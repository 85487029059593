let users = [
    {
        user_name: "yoav",
        nickname: "Yoav Tamir",
        password: "123abc",
        picture_url: "https://media-exp1.licdn.com/dms/image/C5603AQE_ZKnvhgLTSQ/profile-displayphoto-shrink_800_800/0/1602488234599?e=1654128000&v=beta&t=j0NHdvjAcjhnP8uwL9mt4yrayIx9ktY9aMUtekANz0U",
        chats: {


            "dvir": [
                {
                    is_it_me: true,
                    text: "whats up dvir?",
                    date: "00:00",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:00",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "image",
                    date: "00:01",
                    media: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/%D7%94%D7%95%D7%A8%D7%93%D7%94.png?alt=media&token=4ca9e456-173a-48aa-88a5-4c5cb0b1c634",
                    mediaType: "image"
                },
                {
                    is_it_me: true,
                    text: "video",
                    date: "00:01",
                    media: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                    mediaType: "video"
                },
                {
                    is_it_me: true,
                    text: "voice",
                    date: "00:01",
                    media: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/Kalimba.mp3?alt=media&token=acd5e972-04f4-44d5-ad67-c9b02dff3b5e",
                    mediaType: "voice"
                }
            ],
            "yahel": [
                {
                    is_it_me: true,
                    text: "whats up yahel?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                }
            ],
             "liad": [
                {
                    is_it_me: true,
                    text: "whats up liad?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                }
            ],

        }

    },

    {
        user_name: "yahel",
        nickname: "Yahel Jacoby",
        password: "123abc",
        picture_url: 'https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/WhatsApp%20Image%202022-04-22%20at%2015.50.37.jpeg?alt=media&token=ed19995c-547b-4d6b-8f3e-f463d23efd53' ,
        chats: {
            "yoav": [
                {
                    is_it_me: true,
                    text: "whats up yoav?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "image",
                    date: "00:01",
                    media: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/%D7%94%D7%95%D7%A8%D7%93%D7%94.png?alt=media&token=4ca9e456-173a-48aa-88a5-4c5cb0b1c634",
                    mediaType: "image"
                },
                {
                    is_it_me: true,
                    text: "video",
                    date: "00:01",
                    media: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                    mediaType: "video"
                },
                {
                    is_it_me: true,
                    text: "voice",
                    date: "00:01",
                    media: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/Kalimba.mp3?alt=media&token=acd5e972-04f4-44d5-ad67-c9b02dff3b5e",
                    mediaType: "voice"
                }
            ]
            ,
            "dvir": [
                {
                    is_it_me: true,
                    text: "whats up dvir?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                }
            ] ,
            "liad": [
                {
                    is_it_me: true,
                    text: "whats up liad?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"
                },
                {
                    is_it_me: false,
                    text: "all good, how are you?",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                },
                {
                    is_it_me: true,
                    text: "Fine, Thank you!",
                    date: "00:01",
                    media: null,
                    mediaType: "text"

                }
            ],

        }
    },
    {
        user_name: "dvir",
        nickname: "Dvir Elkabetzs",
        password: "123abc",
        picture_url: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/WhatsApp%20Image%202022-04-22%20at%2016.41.14.jpeg?alt=media&token=1bba4445-fb29-4088-8337-ce26afd93a46",

    },

    {
        user_name: "liad",
        nickname: "Liad Mor",
        password: "123abc",
        picture_url: "https://firebasestorage.googleapis.com/v0/b/whatsapp-c2140.appspot.com/o/%D7%94%D7%95%D7%A8%D7%93%D7%94.png?alt=media&token=4ca9e456-173a-48aa-88a5-4c5cb0b1c634"
    }
];

export default users;

